<template>
<div class="primary">
    
    <loading v-if="loading" />
    <v-container fluid>
        <v-row class="pa-12 mt-12">
            <h1 class="secondary--text">
                الاطباء
            </h1>
            <v-spacer />
            <v-btn outlined @click="$router.go(-1)" class="mr-12 btn">
                <h4>رجوع</h4>
                <v-icon>
                    fi fi-rr-arrow-small-left
                </v-icon>
            </v-btn>
        </v-row>
        <v-row>
            <v-col cols="12" md="2" v-for="(item,index) in items" :key="index">
                <v-card outlined style="border-radius: 40px" >
                    <v-container fluid>
                        <v-row justify="center" align="center" class="text-center">
                            <v-col cols="12">
                                <v-card style="border-radius: 40px" min-height="210" outlined>
                                    <v-avatar height="100%" width="100%" full style="border-radius: 40px" v-if="item.imageUrl">
                                        <v-img :src="$url+'/'+item.imageUrl" min-height="260"/>
                                    </v-avatar>
                                    <v-avatar height="100%" width="100%" style="border-radius: 40px" v-else>
                                        <v-img  src="@/assets/images/photo.svg" min-height="260"/>
                                    </v-avatar>
                                </v-card>
                                <br/>
                            <!-- </v-col>
                            <v-col cols="8"> -->
                                <h2 class="primary--text mt-2">{{ item.fullName }}</h2>
                                <strong>{{ item.description }}</strong>
                                <br/>
                                <v-btn :to="'/ourDoctors/'+item.id" block rounded class="btn mt-2"  color="primary" outlined>ملف الطبيب</v-btn>
                            </v-col>
                            <!-- <v-col cols="12">
                                <v-container>
                                    <v-expansion-panels outlined flat style="border: solid 1px #E0E0E0; border-radius: 40px" v-if="item.doctorArticles.length > 0">
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                مقالات الطبيب
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-chip v-for="(a, i) in item.doctorArticles" :key="i" color="primary" dark>
                                                    <a :href="$url+'/'+a.fileUrl" class="white--text">{{ a.title }}
                                                    </a>
                                                    <v-btn icon @click="deleteArticle(a.id)">
                                                    <v-icon  class="mr-2" small>fi fi-rr-cross-circle</v-icon>
                                
                                                    </v-btn>
                                                </v-chip>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-container>
                            </v-col> -->
                        </v-row>
                    </v-container>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
    <v-footer>
        <v-avatar tile size='150' class="mt-1 mx-12">
            <v-img contain src="@/assets/images/logo.png"></v-img>
        </v-avatar>
        <v-spacer />
        <div class="mx-12">
            <!-- <h3>07744700012</h3>
            <h3>07744700013</h3> -->
            <h3 >
                <span @click="makePhoneCall()" class="cur-pointer" dir="ltr">5055</span>

                <v-icon class="mr-2" color="white">
                    fi fi-rr-phone-flip
                </v-icon>
            </h3>
        </div>
    </v-footer>
</div>
</template>

<style scoped>
table {
    width: 100%;
}

td,
th {
    text-align: center;
    padding: 14px;
}

.secondary--text {
    border-collapse: separate;
    border-spacing: 0 10px;
}

.secondary--text td {
    border-radius: 30px;
}
</style>

<script>
import loading from '../../components/Loading.vue';
export default {
    components: {
        loading
    },

    data() {
        return {
            nextDay: '',
            nextDayNameInArabic: '',
            searchName: '',
            items: [],
            loading: false
        }
    },

    created() {
        this.getItems()

        let today = new Date();
        // today.setDate(today.getDate() + 1);
        today.setDate(today.getDate());
        let arabicDayNameFormatter = new Intl.DateTimeFormat('ar-EG', {
            weekday: 'long'
        });
        let nextDayNameInArabic = arabicDayNameFormatter.format(today);
        this.nextDayNameInArabic = nextDayNameInArabic;
        let nextDay = today.getFullYear() + '/' + (today.getMonth()) + '/' + today.getDate();
        // let nextDay = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
        console.log(nextDay);
        this.nextDay = nextDay;

    },

    methods: {

        makePhoneCall() {
            window.open("tel:5055");
        },

        async getItems() {
            this.loading = true
            try {
                let res = await this.$http.get(`Doctor`)
                this.items = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
